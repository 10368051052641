import 'lazysizes';
import Headroom from 'headroom.js';
import '../js/vendor/slick';
import $ from 'jQuery';

document.addEventListener('lazybeforeunveil', function(e){
  let bg = e.target.getAttribute('data-bg');
  if(bg){
      e.target.style.backgroundImage = 'url(' + bg + ')';
  }
});

const Mangrove = {
  init() {
    this.cache();
    this.cacheImages()
    this.events();
    this.initHeadroom();
    this.initSliders();
    this.initBackToTop();
    this.initNav();
    this.sectionFrac();
    this.newTabBtns();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on(
      'click',
      '.js-hamburger',
      event => this.showMobileNav(event)
    );
    this.$doc.on(
      'click',
      '.js-share',
      event => this.openShareWindow(event)
    );

    this.$doc.on (
      'click',
      '.block--hero__nav a',
      event => this.handleSubNavClick(event)
    )

    this.$doc.on (
      'click',
      '.block--hero__nav__toggle',
      event => this.handleSubNavToggle(event)
    )
    this.$doc.on(
      'click',
      '.navitem__signin a',
      event => this.handleSignIn(event)
    )
    this.$doc.on(
      'click',
      '.navitem__button a',
      event => this.handleNewWindow(event)
    )
    this.$doc.on(
      'submit',
      '.subscribe-form',
      event => this.handleSubscribe(event)
    )
    this.$doc.on(
      'focus',
      '.required',
      event => $(event.currentTarget).removeClass('has-error')
    )
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader);
    headroom.init();
  },
  initSliders() {
    $('.js-carousel').slick({
      autoplay: true,
      fade: true,
      cssEase: 'linear',
      speed: 500,
      autoplaySpeed: 4000
    });
  },
  initBackToTop() {
    if ($('#back-to-top').length) {
      let scrollTrigger = 100,
      backToTop = function () {
        let scrollTop = $(window).scrollTop();
        if (scrollTop > scrollTrigger) {
          $('#back-to-top').addClass('show');
        } else {
          $('#back-to-top').removeClass('show');
        }
      };
      backToTop();
      $(window).on('scroll', function () {
        backToTop();
      });
      $('#back-to-top').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
          scrollTop: 0
        }, 600);
      });
    }
  },
  newTabBtns() {
    if ($('.js-new-tab').length) {
      $('.js-new-tab a').attr("target","_blank");
    }
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    this.$header.toggleClass('nav-active');
    this.$body.toggleClass('u-prevent-scroll');
    if ( this.$mobileNav.hasClass('is-active') ) {
      this.$hamburger.attr("aria-pressed", "true");
    } else {
      this.$hamburger.removeAttr("aria-pressed");
    }
  },
  handleSubNavClick(e) {
    let href = e.currentTarget.href;

    if (href.includes('#')) {
      e.preventDefault()

      let elm = e.currentTarget;
      let target = $(`section${elm.hash}, div${elm.hash}, h3${elm.hash}, h2${elm.hash}, h1${elm.hash}`);
      let $menu = $('.block--hero__nav__menu-mobile');
      let $subnav = $('.block--hero__nav');
      let navHeight =  $menu.height() <= 1 ? $subnav.outerHeight() : $subnav.outerHeight() - $menu.outerHeight();
          navHeight = !$('.block--hero__nav').hasClass('stick') ? navHeight + $menu.outerHeight() : navHeight;
      let target_top = target ? ( target.offset().top - navHeight ) : null;
      if (target  &&  !$(e.target).hasClass('block--hero__nav__toggle')) {
        $('html,body').animate({
          scrollTop: target_top
          }, 500, () => this.setCurrentSection(elm.hash.substr(1))
        );
        return false;
      }
    }
  },
  handleSubNavToggle(e) {
    e.preventDefault()
    if (e.target == e.currentTarget) {
      let $menu = $('.block--hero__nav__menu-mobile');
      let actions = {
        open: () => { $('.block--hero__nav').attr('data-open', true) },
        close: () => { $('.block--hero__nav').attr('data-open', false) }
      }
      this.doToggle($menu, actions);
    }
  },
  initNav() {
    this.stick_selector = null;
    //if ( $('header').length ) this.stick_selector = 'header';
    if ( $('.block--hero__nav').length ) this.stick_selector = 'block--hero__nav';

    if ( this.stick_selector ) {
      this.initStickyNav()
      this.resetNav()

      $(window).scroll(this.initStickyNav.bind(this));
      $(window).resize(this.resetNav.bind(this));
    }
  },
  initStickyNav() {
    this.resetNav()
     let {stick_selector} = this;
     let window_top = $(window).scrollTop();
     let div_top = $(`.${stick_selector}__anchor`).offset().top;
     let phantomHeight = $(`.${stick_selector}`).outerHeight();
     let name = $('h1').html();

    $('.navpage__name').html(`${name} &nbsp;&nbsp;/&nbsp;&nbsp;  `)
     if ( window_top > div_top ) {
       if (div_top > 0) $(`.${stick_selector}__anchor`).height(phantomHeight);
       $(`.${stick_selector}`).addClass('stick');
       $(`.${stick_selector}`).css({
         marginLeft: 0,
       });

     } else {
       $(`.${stick_selector}__anchor`).height(0);
       $(`.${stick_selector}`).css({width: ''});
       $(`.${stick_selector}`).css({
         marginLeft: '',
       });
       $(`.${stick_selector}`).removeClass('stick');

     }
   },
   doToggle (context, actions) {
    if ( context.height() <= 1 ) {
      if (actions) actions.open()
     context.animate({
       height: context.get(0).scrollHeight
      }, 500 );
    } else {
      if (actions) actions.close()
      context.animate({
        height: '1px'
      }, 500 );
    }
  },
   resetNav(e) {
    let {stick_selector} = this;
    let $menu = $(`.${stick_selector}__menu-mobile`);
    let $subnav = $(`.${stick_selector}`);
    let is_tablet = $(window).width() <= 959 ? true : false;

    if ( is_tablet && $('[data-open="true"]') ) {
      $(`.${stick_selector}`).attr('data-open', false)
      $menu.css({height: '1px'});
    }

    if ( $subnav.css('top') != 0 ) {
      $subnav.css({ top: 0 });
    }

  },
  sectionFrac() {
    let self = this;

    let handleFrac = function(fracs, previousFracs) {
      if(fracs.visible >= 0.2) {
        if ( ! $(this).hasClass('section--onscreen') ) {

          $(this).addClass('section--onscreen');

          self.setCurrentSection($(this).attr('id'))
        }
      } else {
        if ( $(this).hasClass('section--onscreen') ) {

          $(this).removeClass('section--onscreen');

        }

      }
    };

    let fracs = $('h3, h2, h1').fracs(handleFrac);
  },
  setCurrentSection(current) {
    let $menuItems = $('.block--hero__nav.stick a');

    if ( current ) {
      $menuItems.each(function() {
        let section = this.hash;
          if ( section.substr( 1 ) == current ) {
            $(this).addClass('is-active')
            $(this).removeClass('is-inactive')
          } else {
            $(this).addClass('is-inactive')
            $(this).removeClass('is-active')
          }
      })
    }
  },
  handleSignIn(event) {
    event.preventDefault();

    let healcode = $('.healcode-sigin-link');
        healcode[0].click();
  },
  handleNewWindow(event) {
    event.preventDefault();

    let link = $(event.currentTarget).attr('href');
    
    window.open(link);
  },
  handleSubscribe(event) {
    event.preventDefault();

    const { validateSubscribe, sendSubscribe } = this;
    const $form = $(event.currentTarget)
    const $email = $form.find('[name="email"]')
    const emailAddress = $email.val()
    const ajaxURL = $form.find('[type="submit"]').data('ajaxUrl')
    const data = {
      "email": encodeURIComponent(emailAddress),
      "action" : 'subscribe_form',
    };
    
    if (validateSubscribe(emailAddress)) sendSubscribe(ajaxURL, data)
    if (!validateSubscribe(emailAddress)) $email.addClass('has-error');
  },
  validateSubscribe(email) {
    const pattern = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
    if (email !== '') return pattern.test(email);
    return false
  },
  sendSubscribe(url, data) {
    const $subscribeForm = $('.subscribe-form');
    const $sendingMessage = $('.subscribe-sending').length && $('.subscribe-sending');
    const $confirmationMessage = $('.subscribe-confirmation');
    
    $subscribeForm.hide()
    $sendingMessage && $sendingMessage.show()

    $.post(url, data, (response) => {
      if (response) {
        $sendingMessage && $sendingMessage.hide()
        $confirmationMessage.show()
        $subscribeForm.append(response);
      }
    })
  },
  cacheImages(){
    $('.w-bg-img:not(.loadingImage)').each(function(){
      if ($(this).attr('data-bg') ) {
        var $bg = $(this);
        var image = new Image();
        var $previewImage = $bg.find('.loadingImage');
        var HDsrc = $bg.attr('data-bg');
  
        image.onload = function() {
          $bg.css('background-image', 'url(' + image.src + ')');
          setTimeout(function() {
            $bg.addClass('loaded');
            $previewImage.addClass('loaded');
          }, 300)
        }
        image.src = HDsrc;
      }
    });
  }
};

Mangrove.init();
